/**
 * @fileoverview 路由
 * @author 35820 <hua_haoze@dahuatech.com>
 * @date 2019-10-30
 */

import { hashPrefix, pathPrefix } from './router.helper'

function router(registry: Registry, paths: Paths): any {

    return function (): boolean {
        return AppMatched(registry, paths);
    }
}

function AppMatched(registry: Registry, paths: Paths): any {

    const { path, hash = false } = registry
    if (!path) throw new Error('app\'s path must be string or array.')
    let matching = false
    // 如果该应用 有多个需要匹配的路劲
    if (Array.isArray(path)) {
        matching = path.some((subPath: string): boolean => {
            return hash ? hashPrefix(subPath) : pathPrefix(subPath, paths)
        });
    }
    // 普通情况
    else {
        matching = hash ? hashPrefix(path) : pathPrefix(path, paths)
    }
    return matching
}

export { router, AppMatched }