/**
 * @fileoverview 启动
 * @author 35820 <hua_haoze@dahuatech.com>
 * @date 2019-10-30
 */

const singleSpa = require('./lib/single-spa/single-spa')

import { Register } from './register/register'

function Bootstrap(registryUrl: string, paths: Paths, localRegistries: Registry[], customLifeCycle: any,) {
    // life cycle
    const lifeCycles = Object.assign({
        beforeLoad: [async<T extends object>(app: Registry, register: Register<T>): Promise<any> => {
            return register.preLoadStyles(app)
        }],
        beforeMount: [async<T extends object>(app: Registry, register: Register<T>): Promise<any> => {
            register.loadStyle(app)
        }],
        afterMount: [async<T extends object>(app: Registry, register: Register<T>): Promise<any> => {
            const { root } = app;
            return root ? register.afterRootAppMount(app) : {}
        }],
        afterUnmount: [async<T extends object>(app: Registry, register: Register<T>): Promise<any> => {
            register.unloadStyle(app);
        }]
    }, customLifeCycle)

    const register = new Register(registryUrl, lifeCycles, paths)

    register.registerDHApps(localRegistries)

    singleSpa.start()
}

module.exports = Bootstrap
