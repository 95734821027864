const { join } = require('path')

// hash path
export function hashPrefix(path: string): boolean {
    return window.location.hash.startsWith(`#${path}`)
}

// normal path
export function pathPrefix(path: string, mcm_paths: Paths): boolean {
    let match: boolean = false;
    let fullPath: string = join(mcm_paths.PUBLIC_PATH, path);
    let pathName: string = window.location.pathname;
    if (pathName && pathName.indexOf(fullPath) === 0) {
        const char: string = pathName.charAt(fullPath && fullPath.length);
        if (char === '/' || char === '') {
            match = true;
        }
    }
    return match;
}