/**
 * @fileoverview 工具类
 * @author 35820 <hua_haoze@dahuatech.com>
 * @date 2019-10-30
 */
const { join } = require('path');

// open debug model
export const DEBUG = true

export function loadModules(url: string, paths: Paths): Promise<any> {
    if (!url) return
    const PUBLIC_PATH = paths.PUBLIC_PATH
    const APP_DIR = paths.APP_DIR
    let realUrl = ''
    if (url.includes('\\')) {
        url = url.replace(/\\/g, '/')
    }

    // protocol url
    // absolute root url
    // http://, //10.33.33.33, /mcm/apps/xxxx
    if (url.includes(`/${APP_DIR}`) && url.startsWith(PUBLIC_PATH) || url.startsWith('//') || url.includes(':')) {
        realUrl = url
    }

    else if (!url.includes(`/${APP_DIR}`)) {
        // absolute url
        // /login/index.js
        if (url[0] === '/') {
            realUrl = join(PUBLIC_PATH, `/${APP_DIR}`, url)
        }
        // relative url
        // ./apps
        else if (url[0] === '.' && url.includes(`/${APP_DIR}`)) {
            realUrl = url
        }

        else {
            const sliceUrl = url.slice(url.lastIndexOf('./') + 1, url.length)
            realUrl = join(PUBLIC_PATH, `/${APP_DIR}`, sliceUrl)
        }
    }

    else {
        realUrl = url
    }

    return SystemJS.import(realUrl);
}

export function unloadStyleLink(appName: string) {
    Array.from(document.styleSheets).forEach(style => {
        if (style.href && style.href.includes(`/css/${appName}`)) {
            style.disabled = true;
        }
    })
}

export function loadStyleLink(appName: string) {
    Array.from(document.styleSheets).forEach(style => {
        if (style.href && style.href.includes(`/css/${appName}`)) {
            style.disabled = false;
        }
    })
}