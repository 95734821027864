/**
 * @fileoverview 消息总线
 * @author 35820 <hua_haoze@dahuatech.com>
 * @date 2019-10-30
 */

interface Map<K, V> {
	clear(): void;
	delete(key: K): boolean;
	forEach(callbackfn: (value: V, index: K, map: Map<K, V>) => void, thisArg?: any): void;
	get(key: K): V;
	has(key: K): boolean;
	set(key: K, value?: V): Map<K, V>;
	size: number;
}

interface MessageBus {
	stores: Map<string, any>;
	register(name: string, store: any): void;
	dispatch(event: dispatchEvent): void;
	getStore(name: string): any;
	hasStore(name: string): boolean;
}

interface dispatchEvent {
	type: string;
	payload: any;
}

export class MCMDistributor implements MessageBus {
	stores: Map<string, any>;
	constructor() {
		this.stores = new Map();
	}

	dispatch(event: dispatchEvent = { type: '', payload: null }): void {
		if (!event.type) {
			console.error(`Please dispatch the even in format {type: string, payload: any}`);
			return;
		}
		this.stores.forEach((storeInstance: any) => {
			storeInstance._actions
				? storeInstance._actions[event.type]
					? storeInstance.dispatch(event)
					: {}
				: storeInstance.dispatch(event);
			// for vuex modules
			if (storeInstance._modulesNamespaceMap) {
				Object.keys(storeInstance._modulesNamespaceMap).forEach((key: string) => {
					const { _rawModule, context } = storeInstance._modulesNamespaceMap[key];
					if (_rawModule && _rawModule.actions[event.type]) context.dispatch(event);
				});
			}
		});
	}

	register(name: string, store: any): void {
		this.stores.set(name, store);
	}

	hasStore(name: string): boolean {
		return this.stores.has(name);
	}

	getStore(name: string): any {
		let instance = null;
		if (name && this.hasStore(name)) {
			instance = this.stores.get(name);
		} else {
			console.error(`Can't find store instantce named ${name}`);
		}
		return instance;
	}
}
