/**
 * @fileoverview 注册器
 * @author 35820 <hua_haoze@dahuatech.com>
 * @date 2019-10-30
 */

// tslint: disable
const pullAllBy = require("lodash/pullAllBy");
const head = require("lodash/head");
const { registerApplication } = require("../lib/single-spa/single-spa");
import { router, AppMatched } from "../router/router";
import { MCMDistributor } from "../store/mcm-distributor";
import { loadModules, unloadStyleLink, loadStyleLink } from "../utils/helps";

declare type Lifecycle<T extends object> = (
    app: Registry,
    register: Register<T>
) => Promise<any>;

export type LifeCycles<T extends object> = {
    beforeLoad?: Lifecycle<T> | Array<Lifecycle<T>>; // function before app load
    beforeMount?: Lifecycle<T> | Array<Lifecycle<T>>; // function before app mount
    afterMount?: Lifecycle<T> | Array<Lifecycle<T>>; // function after app mount
    beforeUnmount?: Lifecycle<T> | Array<Lifecycle<T>>; // function after app unmount
    afterUnmount?: Lifecycle<T> | Array<Lifecycle<T>>; // function after app unmount
};

//注册器
export class Register<T extends object> {
    mcm_distributor: MCMDistributor;
    registeredApps: Array<any>;
    lifeCycles: LifeCycles<T>;
    registryList: Array<any>;
    paths: Paths;
    rootAppName: string;
    private registryUrl: string;

    constructor(url: string, lifeCycles: LifeCycles<T> = {}, paths: Paths) {
        if (!url) throw new Error("the registry url is undefined");
        this.paths = paths;
        this.registryUrl = url;
        this.registeredApps = [];
        this.registryList = [];
        this.lifeCycles = lifeCycles;
        this.mcm_distributor = new MCMDistributor();
        window.MCM_DISTRIBUTOR = this.mcm_distributor;
    }

    toArray<T>(array: T | T[]): T[] {
        return Array.isArray(array) ? array : [array];
    }

    execHooksChain<T extends object>(
        hooks: Array<Lifecycle<T>>,
        app: Registry,
        register: any
    ): Promise<any> {
        if (hooks.length) {
            return hooks.reduce(
                (chain, hook) => chain.then(() => hook(app, register)),
                Promise.resolve()
            );
        }

        return Promise.resolve();
    }
    //注册app，找出base模块，调用预加载方法
    async registerDHApps(localRegistries: Registry[] = []): Promise<any> {
        console.time("MCM Register Time: ");
        // get registry list
        const { default: registryList } = await SystemJS.import(
            this.registryUrl
        );

        if (Array.isArray(localRegistries)) {
            this.registryList = [...localRegistries.filter(i => !i.root)];
        }

        if (registryList && Array.isArray(registryList)) {
            this.registryList = [...this.registryList, ...registryList];
            // register app in turn
            const rootApp = this.registryList.filter(
                (registry: Registry) => registry.root
            );
            // has root app
            if (rootApp.length > 0) {
                this.rootAppName = rootApp[0].name;
                // get next app resources
                await this.preLoadNextApp(rootApp[0], this.mcm_distributor);
            } else {
                this.registryList.forEach((registry: Registry) => {
                    this.registerApp(registry, this.mcm_distributor);
                });
            }

            console.log("Get apps: ");
            console.table(this.registryList);
        } else {
            throw new Error("the registry must be array type");
        }

        console.timeEnd("MCM Register Time: ");
    }
    //预加载样式文件（拿到实际路径通过SystemJS引入文件）
    async preLoadStyles(app: Registry) {
        const { styles } = app;
        if (styles) {
            switch (Array.isArray(styles)) {
                case true:
                    for (let css of styles) {
                        await loadModules(css, this.paths);
                    }
                    break;
                case false:
                    await loadModules(String(styles), this.paths);
                    break;
                default:
                    break;
            }
        }
    }
    //销毁样式（通过修改StyleSheet中模块的嵌入样式表的disable值控制模块的样式销毁）
    unloadStyle(app: Registry) {
        const { name } = app;
        !app.root && unloadStyleLink(name);
    }
    //加载样式（通过修改StyleSheet中模块的嵌入样式表的disable值控制模块的样式加载）
    loadStyle(app: Registry) {
        const { name } = app;
        !app.root && loadStyleLink(name);
    }
    //注册store
    async registerStore(
        app: Registry,
        distributor: MCMDistributor
    ): Promise<any> {
        // app info
        let { name, store, realName } = app;
        name = realName ? realName : name;

        if (distributor.hasStore(name)) {
            return;
        }

        // store instance
        let storeInstance = null;
        // rawModule
        let storeRawModule = null;
        try {
            // if have store url,
            // get the store module
            const storeModule = store
                ? await loadModules(store, this.paths)
                : { default: null };
            storeInstance =
                storeModule && storeModule.store
                    ? storeModule.store
                    : storeModule.default;
            storeRawModule = storeModule.default;
        } catch (error) {
            console.error(
                `Can't register the store of module ${name}, error: \n`
            );
            console.error(error && error.message);
            return;
        }

        if (storeInstance) {
            if (storeRawModule) {
                // if app export raw store data
                // register as root's modules
                const rootStore = distributor.getStore(this.rootAppName);
                rootStore.registerModule(name, storeRawModule);
            } else {
                // if no raw data
                // register as instance
                distributor.register(name, storeInstance);
            }
        } else if (storeRawModule) {
            const rootStore = distributor.getStore(this.rootAppName);
            rootStore.registerModule(name, storeRawModule);
        }
    }
    //base模块注册成功后，在base模块的afterMount周期中执行其余子模块的注册
    afterRootAppMount(rootApp: Registry): void {
        const restApps = pullAllBy(this.registryList, [rootApp], "root");
        Array.isArray(restApps) &&
            restApps.forEach((registry: Registry) => {
                this.registerApp(registry, this.mcm_distributor);
            });
    }
    //预加载APP，当模块为base则立即加载样式、引入入口文件、注册公共store
    async preLoadNextApp(rootApp: Registry, distributor: MCMDistributor) {
        const restApps = pullAllBy(this.registryList, [rootApp], "root");
        const nextMountApp = head(
            restApps.filter((registry: Registry) => {
                return AppMatched(registry, this.paths);
            })
        );
        try {
            if (nextMountApp) {
                await this.registerStore(rootApp, distributor);
                // await this.registerStore(nextMountApp, distributor);
                await loadModules(nextMountApp.entry, this.paths);
                await this.registerApp(rootApp, distributor);
            } else {
                await this.registerApp(rootApp, distributor);
            }
        } catch (error) {
            await this.registerApp(rootApp, distributor);
            console.error(error);
        }

        // Pre registration store
        // for (let i = 0; i < restApps.length; i++) {
        // 	try {
        // 		if (nextMountApp) {
        // 			if (restApps[i].name !== nextMountApp.name) {
        // 				await this.registerStore(restApps[i], this.mcm_distributor);
        // 			}
        // 		} else {
        // 			await this.registerStore(restApps[i], this.mcm_distributor);
        // 		}
        // 	} catch (error) {
        // 		console.error(`Error when registerStore`);
        // 		console.log(error);
        // 	}
        // }
    }
    //注册APP，将singleSPA的生命周期结合
    async registerApp(
        app: Registry,
        distributor: MCMDistributor
    ): Promise<any> {
        // app info
        const { name, entry, root } = app;
        const paths: Paths = this.paths;

        // custom props
        // inject into app instance when mounting
        const props = {
            MCM_APP: app,
            MCM_DISTRIBUTOR: distributor,
            MCM_PUBLIC_URL: paths.PUBLIC_PATH,
            MCM_APP_URL: paths.APP_DIR,
            ROOT_APP_NAME: this.rootAppName
        };
        registerApplication(
            name,
            async (): Promise<any> => {
                await this.registerStore(app, distributor);
                //拼接
                const appData = await loadModules(entry, this.paths);
                // life cycle split
                const { bootstrap, mount, unmount } = appData;

                return {
                    bootstrap: [
                        async () =>
                            this.execHooksChain(
                                this.toArray(this.lifeCycles.beforeLoad || []),
                                app,
                                this
                            ),
                        bootstrap
                    ],
                    mount: [
                        async () =>
                            this.execHooksChain(
                                this.toArray(this.lifeCycles.beforeMount || []),
                                app,
                                this
                            ),
                        mount,
                        async () =>
                            this.execHooksChain(
                                this.toArray(this.lifeCycles.afterMount || []),
                                app,
                                this
                            )
                    ],
                    unmount: [
                        async () =>
                            this.execHooksChain(
                                this.toArray(
                                    this.lifeCycles.beforeUnmount || []
                                ),
                                app,
                                this
                            ),
                        unmount,
                        async () =>
                            this.execHooksChain(
                                this.toArray(
                                    this.lifeCycles.afterUnmount || []
                                ),
                                app,
                                this
                            )
                    ]
                };
            },
            //base模块activeWhen一直为true，其他模块根据路由监听singleSPA会触发以上的生命周期
            root ? () => true : router(app, this.paths),
            props
        );

        console.log(
            `%c app ${name} is registered successfully `,
            "border-radius: 3px; background: #555; color: #bada55"
        );

        this.registeredApps.push(app);
    }
}
